.ParticalBG {
    z-index: -5;
    position: relative;    
}

.home{
    z-index: -1;
    position: relative;
}

h2 {
    color: white;
    z-index: 2;
    position: relative;
}

.HomeTitle {    
    font-family: 'Roboto', sans-serif;
}


#IDOBG {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

.homeMID {
    padding: 90px;
    width: 100vw;
    height: 50vh;
    color: white;
    background-color: black;
    opacity: 0.9;
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
}

@media screen and (max-width: 600px){
    .homeMID{
        padding: 80px;
        width: 100vw;
        height: 50vh;
        color: white;
        background-color: black;
        opacity: 0.9;
        font-family: 'Roboto', sans-serif;
        font-size: 30px;
    }
}